declare module 'o365.pwa.declaration.shared.dexie.objectStores.UserDevice.d.ts' {
    export interface IUserDeviceOptions {
        deviceRef?: string;
        deviceInfoString: string;
    }

    export class UserDevice {
        static objectStoreDexieSchema: string;

        deviceRef: string;
        deviceInfoString: string;

        constructor(options: IUserDeviceOptions);

        save(): Promise<void>;
        delete(): Promise<void>;
    }
}

export {}
